import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase/firebase";
import Loader from "../components/Loader";
import moment from "moment";
import { readTime } from "../utils/helper";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Recommended from "../components/Recommend";
import BackToTopButton from "../components/BackToTopButton";
import { AuthUser } from "../context/Context";

const SinglePost = () => {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const path = window.location.href; //grabs url
  const { postId } = useParams(); //takes the end of the post url
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = AuthUser();

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(path); //copies to clipboard
      toast.success("Copied!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  //increment page views
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender?.current) {
      const incrementPageView = async () => {
        try {
          const ref = doc(db, "posts", postId);
          await updateDoc(
            ref,
            {
              pageViews: increment(1), //imported from firebase
            },
            { merge: true }
          );
        } catch (error) {
          toast.error(error.message);
        }
      };
      incrementPageView();
    }
    isInitialRender.current = false; //if not initial render we don't want the increment to happen
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const postRef = doc(db, "posts", postId);
        const getPost = await getDoc(postRef);

        if (getPost.exists()) {
          const postData = getPost.data(); //Get the post
          if (postData?.userId) {
            //Get user data from the post
            const userRef = doc(db, "users", postData?.userId);
            const getUser = await getDoc(userRef);

            if (getUser.exists()) {
              const { created, ...rest } = getUser.data(); //this line fixes the invalid date error
              setPost({ ...postData, ...rest, id: postId }); //save data to the post object useState
            }
          }
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const { title, content, postImg, caption, userImg, displayName, pageViews } =
    post; //get all the data from post to use below

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div ref={contentRef}>
          <div className="w-full mt-[80px] md:mt-0 md:w-[60%] lg:w-[70%] xl:w-[80%] mx-auto md:p-[3rem] md:bg-white min-h-screen md:shadow relative">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-1 flex-wrap">
                {post.tags &&
                  post.tags?.map((tag, i) => (
                    <span
                      key={i}
                      className="text-xs rounded-full px-2 border border-gray-400 text-green-500 font-medium"
                    >
                      {tag}
                    </span>
                  ))}
                <span></span>
              </div>
              <button
                onClick={() => navigate("/")}
                className="text-sm flex items-center hover:bg-gray-200 bg-gray-100 rounded-full p-2 transition-all"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>

            <h2 className="text-2xl md:text-4xl text-gray-800 font-extrabold">
              {title}
            </h2>

            <div
              className={`flex items-center justify-between gap-2 flex-wrap mt-6 mb-3`}
            >
              <div className="flex items-center gap-2">
                <img
                  src={userImg}
                  alt={displayName}
                  className="cursor-pointer h-10 w-10 object-cover rounded-full"
                  onClick={() => navigate("/about")}
                />
                <span className="text-sm text-zinc-500">
                  By {post.displayName} &#xb7; {moment(post.created).fromNow()}
                  {/* <span className="text-sm">
                  {readTime({ __html: content })} min read
                </span> */}
                </span>
              </div>

              <div className="flex items-center gap-2">
                <span className="text-sm text-zinc-500">{pageViews} Views</span>

                <button
                  className="flex items-center text-sm md:text-md gap-1 text-green-500 hover:bg-gray-100 px-2 py-1 transition-all rounded"
                  onClick={copyLink}
                >
                  <i className="bi bi-link-45deg"></i> Copy link
                </button>
              </div>
              {/* <button
                className="text-sm text-green-500"
                onClick={reactToPrintFn}
              >
                <i className="bi bi-print"></i> Print
              </button> */}
            </div>

            <div className="border-gray-200 border-t border-b py-5">
              {/* {postImg && (
                <>
                  <img
                    className="w-full max-h-[300px] object-cover rounded-lg"
                    src={postImg}
                    alt="post-img"
                  />
                  <span className="text-xs font-extralight">
                    {caption && caption}
                  </span>
                </>
              )} */}
              <div
                className="my-6"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>

            <Recommended post={post} />
          </div>
        </div>
      )}

      <BackToTopButton />
    </>
  );
};

export default SinglePost;
